<template>
  <div class="bg-gray-800 text-gray-200">
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="text-xs">
          <MappingSingleMesh
            v-for="mesh in meshes"
            :key="mesh.id"
            :mesh="mesh"
            :highlighted="highlighted"
            @highlight="$emit('highlight', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const MappingSingleMesh = () => import('@components/editor/scene/blocks/MappingSingleMesh')

export default {
  name: 'MappingSingleBlock',

  components: {
    MappingSingleMesh
  },

  props: ['meshes', 'highlighted']
}
</script>
